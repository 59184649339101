<template>
  <div
    v-if="visivel"
    :id="id"
    class="spinner"
    align-self="center"
  >
    <div class="spinner__area">
      <v-progress-circular
        :size="100"
        indeterminate
        color="#ffffff"
      />
      <div class="mt-2 white--text text-center">
        {{ texto }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    visivel: function () {
      return this.$store.state.Layout.spinnerVisivel;
    },
    texto: function(){
      return this.$store.state.Layout.spinnerTexto;
    }
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.7);
  &__area {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    opacity: 1;

    &--message {
      font-weight: bold;
      font-size: 34px;
      opacity: 1;
    }
  }
}
</style>
